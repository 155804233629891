<template>
  <mobile-page title="" sub_title="Deliver crate to vendor">
    <template v-slot:content>
      <div v-if="message !== null" class="message">{{message}}</div>
      <form v-else @submit.prevent="validate">

        <div class="pd-15 pd-sm-40 bg-gray-200 wd-xl-75p">
          <div class="row row-xs mb-2" v-html="$ExtrinsicLink(extrinsic)"></div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Crate Id</h6>
              <p class="my-0 font-weight-normal">{{crateid}}</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Harvest Location</h6>
              <p class="my-0 font-weight-normal">
                {{pickup.location.lat}}, {{pickup.location.lng}}
                <a href="javascript:void(0);" title="View Location in Map" class="ml-2" @click="viewLocationInMap(pickup.location)">
                  <i class="fa fa-map-marked-alt" />
                </a>
              </p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Farming Community</h6>
              <p class="my-0 font-weight-normal">{{pickup.farming_community}}</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Vegetable</h6>
              <p class="my-0 font-weight-normal">{{pickup.vegetable}}</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Harvest Date</h6>
              <p class="my-0 font-weight-normal">{{pickup.harvest_date}}</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Pulp Temperature At Harvest</h6>
              <p class="my-0 font-weight-normal">{{pickup.pulp_temperature | unitFix(2)}} °C</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Weight At Harvest</h6>
              <p class="my-0 font-weight-normal">{{pickup.weight | unitFix(3)}} Kg</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Purchase Order #</h6>
              <p class="my-0 font-weight-normal">{{pickup.po_number}}</p>
            </div>
          </div>
          <hr>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Distribution Centre Location</h6>
              <p class="my-0 font-weight-normal">
                {{receive.location.lat | unitFix(7)}}, {{receive.location.lng | unitFix(7)}}
                <a href="javascript:void(0);" title="View Location in Map" class="ml-2" @click="viewLocationInMap(receive.location)">
                  <i class="fa fa-map-marked-alt" />
                </a>
              </p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Pulp Temperature At Distribution Centre</h6>
              <p class="my-0 font-weight-normal">{{receive.pulp_temperature | unitFix(2)}} °C</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Weight At Distribution Centre</h6>
              <p class="my-0 font-weight-normal">{{receive.weight | unitFix(3)}} Kg</p>
            </div>
          </div>
          <hr>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Dispatch Location</h6>
              <p class="my-0 font-weight-normal">
                {{dispatch.location.lat}}, {{dispatch.location.lng}}
                <a href="javascript:void(0);" title="View Location in Map" class="ml-2" @click="viewLocationInMap(dispatch.location)">
                  <i class="fa fa-map-marked-alt" />
                </a>
              </p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Weight At Dispatch</h6>
              <p class="my-0 font-weight-normal">{{dispatch.weight | unitFix(3)}} Kg</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Invoice Number</h6>
              <p class="my-0 font-weight-normal">{{dispatch.invoice_number}} Kg</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Challan Number</h6>
              <p class="my-0 font-weight-normal">{{dispatch.challan_number}} Kg</p>
            </div>
          </div>
          <hr>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h4 class="mb-0 font-weight-bold text-left">Deliver Crate</h4>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Delivery Location</h6>
              <p class="my-0 font-weight-normal">
                {{delivery.location.lat | unitFix(7)}}, {{delivery.location.lng | unitFix(7)}}
              </p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12 input-group">
              <input class="form-control" placeholder="Weight" type="number" step=".001" v-model="delivery.weight" :class="{ 'border-danger' : submitted && errors.has('weight') }" data-vv-as="Weight" name="Weight" v-validate="'required|min_value:.01'">
              <div class="input-group-append">
                <span class="input-group-text bg-gray-100" :class="{ 'border-danger' : submitted && errors.has('weight') }">
                  Kg
                </span>
              </div>
            </div>
            <div class="text-danger" v-if="submitted && errors.has('weight')">{{errors.first('weight')}}</div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">QC Passed?</h6>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="qc" id="qcPass" value="Yes" :class="{ 'is-invalid' : submitted && errors.has('qc') }" data-vv-as="QC" v-validate="'required'" v-model="delivery.qc_passed">
                <label class="form-check-label" for="qcPass">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="qc" id="qcFail" value="No" :class="{ 'is-invalid' : submitted && errors.has('qc') }" v-model="delivery.qc_passed">
                <label class="form-check-label" for="qcFail">No</label>
              </div>
              <div class="text-danger" v-if="submitted && errors.has('qc')">{{errors.first('qc')}}</div>
            </div>
          </div>
          <div class="row row-xs">
            <div class="ml-auto col-md-2 col-sm-12">
              <button class="btn rounded btn-az-primary btn-block" type="submit">
                <i class="fa fa-check-circle" /> Sign
              </button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </mobile-page>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "Dispatch",
  props: ["crateid", "registryid", "processdefinitionid", "processid", "process_steps", "extrinsic"],
  data() {
    return {
      submitted: false,
      pickup: {
        crate_id: null,
        location: {
          lat: null,
          lng: null
        },
        farming_community: null,
        vegetable: null,
        harvest_date: null,
        pulp_temperature: null,
        po_number: null,
        weight: null
      },
      receive: {
        location: {
          lat: null,
          lng: null
        },
        weight: null,
        pulp_temperature: null
      },
      dispatch: {
        location: {
          lat: null,
          lng: null
        },
        weight: null,
        invoice_number: null,
        challan_number: null,
      },
      delivery: {
        location: {
          lat: null,
          lng: null
        },
        weight: null,
        qc_passed: null
      },
      location_error: null,
      message: null
    };
  },
  mounted() {
    this.getProcessSteps();

    this.getCurrentLocation();

  },
  methods: {

    viewLocationInMap(location) {
      if (location.lat && location.lng) {
        let url = `http://maps.google.com/maps?q=${location.lat},${location.lng}&z=17`;
        window.open(url);
      }
    },
    async getCurrentLocation() {
      try {
        this.delivery.location = await this.$getLocation({ enableHighAccuracy: true, timeout: Infinity, maximumAge: 0 });
      } catch (e) {
        this.location_error = e;
        this.$toastr.e('Cannot access location', 'Error');
      }
    },
    async validate() {
      this.submitted = true;
      let valid = await this.$validator.validate();

      if (valid) {
        if (typeof this.processid != 'undefined') {
          try {
            EventBus.$emit('openLoader');

            let attributes = [
              {
                name: "Location Lat",
                type: "String",
                value: `${this.delivery.location.lat}`
              },
              {
                name: "Location Lng",
                type: "String",
                value: `${this.delivery.location.lng}`
              },
              {
                name: "Weight (Kg)",
                type: "String",
                value: this.delivery.weight
              },
              {
                name: "QC passed",
                type: "Bool",
                value: (this.delivery.qc_passed == 'Yes') ? 'true' : 'false'
              }
            ];
            let result;
            let processstepid;
            if (!this.process_steps[3].process_step) {
              result = await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps`, {
                process_definition_step: this.process_steps[3].process_definition_step,
                attributes: attributes
              });
              processstepid = result.data.id;
            } else {
              processstepid = this.process_steps[3].process_step;
              result = await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${processstepid}`, { add_attributes: attributes });

            }

            result = await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${processstepid}/attest`, { process_definition_step_index: this.process_steps[3].order });
            this.$toastr.s("Details successfully submited. Thank you.", 'Success');
            this.$router.push({ name: 'summary', params: { crateid: this.crateid, registryid: this.registryid, processdefinitionid: this.processdefinitionid, processid: this.processid, process_steps: this.process_steps, extrinsic: this.extrinsic } });

          } catch (e) {
            console.log(e);
            this.message = "Error saving";
            this.$toastr.e('Error saving', 'Error');
          } finally {
            EventBus.$emit('closeLoader');
          }
        } else {
          this.$toastr.e('Please rescan barcode', 'Error');
          this.message = "Please rescan barcode";
        }
      }
    },
    async getProcessSteps() {
      let self = this;

      if (typeof this.process_steps != 'undefined') {
        try {
          //step 1
          let result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.process_steps[0].process_step}/attributes?page=0&per_page=100`);
          let attributes = result.data.attributes;

          function getAttributeValue(name) {
            let attribute = attributes.find(attribute => attribute.name == name);
            if (!attribute) {
              self.message = 'Error getting attribute: ' + name;
              return null;
            }

            return attribute.fact.value;
          }

          this.pickup.location.lat = getAttributeValue("Location Lat");
          this.pickup.location.lng = getAttributeValue("Location Lng");

          this.pickup.farming_community = getAttributeValue("Farming Community");
          this.pickup.vegetable = getAttributeValue("Vegetable");
          this.pickup.harvest_date = getAttributeValue("Harvest Date");
          this.pickup.pulp_temperature = getAttributeValue("Pulp Temperature (°C)");
          this.pickup.po_number = getAttributeValue("PO Number");
          this.pickup.weight = getAttributeValue("Weight (Kg)");

          //step 2
          result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.process_steps[1].process_step}/attributes?page=0&per_page=100`);
          attributes = result.data.attributes;

          this.receive.location.lat = getAttributeValue("Location Lat");
          this.receive.location.lng = getAttributeValue("Location Lng");
          this.receive.pulp_temperature = getAttributeValue("Pulp Temperature (°C)");
          this.receive.weight = getAttributeValue("Weight (Kg)");

          //step 3
          result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${this.process_steps[2].process_step}/attributes?page=0&per_page=100`);
          attributes = result.data.attributes;

          this.dispatch.location.lat = getAttributeValue("Location Lat");
          this.dispatch.location.lng = getAttributeValue("Location Lng");
          this.dispatch.weight = getAttributeValue("Weight (Kg)");
          this.dispatch.invoice_number = getAttributeValue("Invoice Number");
          this.dispatch.challan_number = getAttributeValue("Challan Number");

        } catch (e) {
          console.log(e);
          this.$toastr.e('Error getting process details', 'Error');
          this.message = "Error getting process details";
        }
      } else {
        this.$toastr.e('Please rescan barcode', 'Error');
        this.message = "Please rescan barcode";
      }
    },
  }
}
</script>
<style scoped>
.message {
  text-align: center;
  margin-top: 50px;
}
</style>